<template>
	<div id="ag-grid-demo">
		<vx-card>
			<!-- TABLE ACTION ROW -->
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowModelType="rowModelType"
				serverSideStoreType="partial"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:cacheBlockSize="500"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@displayed-columns-changed="displayedColumnsChanged"
				@grid-ready="onGridReady"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'
	
	// Cell Renderer
	import CellRendererEncryptedEmail from '@/components/ag-grid-tables/cell-renderer/EncryptedEmailHyperlink.vue'

	export default {
		components: {
			AgGridVue,
			CellRendererEncryptedEmail
		},
		data() {
			return {
				isGridReady: false,
				gridOptions: {},
				gridCount: 0,
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep'
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				rowModelType: "serverSide",
				columnDefs: [
				{
					headerName: 'Registrant Email',
					field: 'registrant_email',
					filter: "agTextColumnFilter",
					filterParams: {
						filterOptions: ["equals", "notEqual", "startsWith", "endsWith", "contains", "notContains", {
							displayKey: 'regex',
							displayName: 'Multiple contains',
							test: function(filterValue, cellValue) {
								return cellValue;
							},
						}, {
							displayKey: 'pattern',
							displayName: 'Pattern',
							test: function(filterValue, cellValue) {
								return cellValue;
							}
						}],
						defaultOption: 'pattern',
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						debounceMs: 1000
					},
					cellRendererFramework: 'CellRendererEncryptedEmail',
					minWidth: 275,
					pinned: 'left',
				},
				{
					headerName: 'Privacy Protected',
					field: 'privacy_protected',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: [1,0],
						suppressMiniFilter: true,
						cellRenderer: (data) => {
							if (parseInt(data.value) === 0) {
								return 'no';
							}
							else if (parseInt(data.value) === 1) {
								return 'yes';
							}
							else {
								return data.value;
							}
						}
					},
					minWidth: 150,
				},
				{
					headerName: 'Blacklisted',
					field: 'blacklisted',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: [1,0],
						suppressMiniFilter: true,
						cellRenderer: (data) => {
							if (parseInt(data.value) === 0) {
								return 'no';
							}
							else if (parseInt(data.value) === 1) {
								return 'yes';
							}
							else {
								return data.value;
							}
						}
					},
					minWidth: 150,
				},
				{					
					headerName: 'Abuse Rate',
					field: 'abuse_rate',
					filter: "agNumberColumnFilter",
					minWidth: 150,
					cellRenderer: (data) => {
						if(data.value != null) {
							if(data.value > 50) {
								return `<div class="badge badge--danger inline">${data.value}%</div>`
							}
							else if(data.value > 4) {
								return `<div class="badge badge--warning inline">${data.value}%</div>`
							}
							else {
								return `<div class="badge badge--success inline">${data.value}%</div>`
							}
						}
						else {
							return ''
						}
					},
				},
				{					
					headerName: 'Domains Registered',
					field: 'domains_registered',
					filter: "agNumberColumnFilter",
					minWidth: 200,
				},
				{					
					headerName: 'Domains Reported',
					field: 'domains_reported',
					filter: "agNumberColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				],
				modifiedColumnState: null,

				// Cell Renderer Components
				components: {
					CellRendererEncryptedEmail
				},
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			totalPages() {
				if(this.gridApi) {
					this.gridCount = this.gridApi.getDisplayedRowCount() - 1
				}
				else {
					this.gridCount = 0
				}
			},
			modifiedColumnState: {
				handler: function(val) {
					localStorage.setItem('col-'+this.$route.name, JSON.stringify({'state': val}));
				},
				deep: true
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			currentTimestamp: function(){
				return this.$store.getters.getCurrentTimestamp;
			},
			formattedTimestamp: function(){
				return this.currentTimestamp != null ? new Date(this.currentTimestamp) : this.currentTimestamp;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			displayedColumnsChanged() {
				if (this.isGridReady) {
					this.modifiedColumnState = this.gridOptions.columnApi.getColumnState();
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onGridReady(params) {
				let self = this;

				// const endpoint = `${this.$SERVERSIDE}/node-sources/registrant-abuse-rate`;
				const endpoint = 'https://mirador.watchtower.space/mirador-sources/registrant-abuse-rate';

				// Set Data Source
				params.api.setServerSideDatasource({
					getRows(params) {
						fetch(endpoint, {
							method: 'post',
							body: JSON.stringify(params.request),
							headers: {"Content-Type": "application/json; charset=utf-8"}
						})
						.then(httpResponse => httpResponse.json())
						.then(response => {
							if(response.lastRow) {
								// clear all overlays
								self.gridApi.hideOverlay();
								params.successCallback(response.rows, response.lastRow);
							}
							else {
								// clear all overlays
								self.gridApi.hideOverlay();
								// show 'no rows' overlay
								self.gridApi.showNoRowsOverlay();
								params.successCallback([], 0);
							}
							
							// Set Column State
							if (!self.isGridReady) {
								let columnState = JSON.parse(localStorage.getItem('col-'+self.$route.name));
								if(columnState) {
									self.gridOptions.columnApi.setColumnState(columnState.state);
								}
							}
							
							// Set isGridReady Flag
							self.isGridReady = true;
						})
						.catch(error => {
							console.error(error);
							params.failCallback();
						})
					}
				});
			},
			getUrlParameter(name) {
				return this.$route.query[name];
			},
			filterAgGrid(model) {
				if(model) {
					this.gridApi.setFilterModel(model);
					this.gridApi.onFilterChanged();
				}
			},
			getContextMenuItems() {
				return [
					'copy',
					'copyWithHeaders',
					'paste',
					'separator',
					'export'
				];
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "updated_at",
					sort: "desc"
					}
				]);
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});

		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>